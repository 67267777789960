import React from "react";
import loadable from "@loadable/component";
import { runtimeConfig } from "../../../common/helpers/config";

const Desktop = loadable(() => import("./Desktop"));
const SubNav = loadable(() => import("./SubNav"));
const Mobile = loadable(() => import("./Mobile"));

function Navigation({ navigation = {}, breadcrumb = [] }) {
  return (
    <>
      <Desktop
        list={navigation.children}
        logo={runtimeConfig.LOGO}
        path={breadcrumb.map(i => i._id)}
      />
      {breadcrumb[1] && (
        <SubNav
          list={breadcrumb[1].children}
          path={breadcrumb.map(i => i._id)}
        />
      )}
      <Mobile list={navigation.children} logo={runtimeConfig.LOGO} />
    </>
  );
}

export default Navigation;
